@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;

  @media (max-width: 769px) {
    scroll-behavior: initial;
  }
}

body {
  margin: 0;
  height: 100%;
}
